import logger from '@crystal-eyes/utils/logger'
import {
  SpanKind,
  SpanStatusCode,
  Span,
  context,
  trace,
  ROOT_CONTEXT,
} from '@opentelemetry/api'

export async function addInstrumentationAttribute(_name: string, _value: any) {}

export async function noticeError(name: string, error: any) {
  logger.error(`noticeError (${name})`, error)
}

export async function noticeWarning(name: string, error: any) {
  logger.warn(`noticeWarning (${name})`, error)
}

export let rootSpan: Span | null = null
export function setRootSpan(
  createNewRoot: (currRoot: Span | null) => Span | null,
) {
  rootSpan = createNewRoot(rootSpan)
}

export async function trackExecution<T>(
  name: string,
  asyncFunc: (span: Span) => Promise<T>,
): Promise<T> {
  const tracer = trace.getTracer('tracked-execution')
  return new Promise((resolve, reject) => {
    let parentCtx = context.active()
    if (rootSpan) {
      parentCtx = trace.setSpan(ROOT_CONTEXT, rootSpan)
    }

    return context.with(
      parentCtx,
      () => {
        return tracer.startActiveSpan(
          name,
          { kind: SpanKind.SERVER },
          async span => {
            try {
              return await asyncFunc(span)
                .catch(error => {
                  span.setStatus({
                    code: SpanStatusCode.ERROR,
                    message: `${error}`,
                  })
                  span.recordException(error)
                  reject(error)
                })
                .then(resp => {
                  resolve(resp as T)
                })
            } finally {
              span.end()
            }
          },
        )
      },
      undefined,
      rootSpan,
    )
  })
}
