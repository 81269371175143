import {
  useCallback,
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'
import useSWR from 'swr'
import { match, P } from 'ts-pattern'
import { AuthDetails, JwtPayload } from '@crystal-eyes/types'
import { authSource } from '@crystal-eyes/config'
import {
  getAuthDetails,
  getAuthFallback,
  getApp,
  getSessionToken,
  doSetAuthDetails,
} from '@crystal-eyes/utils/auth'
import { fetchV3Api } from '@crystal-eyes/utils/apis/v3Api'
import jwtDecode from 'jwt-decode'
import { fetchAuthApi } from '@crystal-eyes/utils/apis/authApi'
import { getDocumentCookie } from '@crystal-eyes/lib/browser'
import { AUTH_TOKEN_COOKIE } from '@crystal-eyes/lib/constants'

export type { AuthDetails }

export type AuthOptions = {
  reactivate?: boolean
  gToken?: string
  twoFactorCode?: string
}

export type AuthState = {
  authed: boolean
  success?: boolean
  data: AuthDetails | undefined
  loading?: boolean
  error?: any
  update: (details: AuthDetails) => Promise<AuthDetails>
  doLogin: (
    email: string,
    password: string,
    opts?: AuthOptions,
  ) => Promise<AuthDetails>
  doLogout: () => Promise<any>
}

export const UseAuthContext = createContext<{
  data: AuthDetails
  error?: any
  loading?: boolean
} | null>(null)

export enum AuthErrors {
  NeedTwoFactor = 'NEED_TWO_FACTOR_AUTH_ERROR',
  Unknown = 'UNKNOWN_AUTH_ERROR',
  Invalid = 'INVALID_AUTH_ERROR',
}

export default function useAuth(): AuthState {
  if (process.env.USE_AUTH_API == 'true') return useNewAuth()

  if (!authSource) throw new Error('useAuth() called before authSource as set!')

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState<any | null>(false)

  const parentContext = useContext(UseAuthContext)
  const fetcher = () => parentContext?.data || getAuthDetails()

  const { data, mutate } = useSWR('global.auth', fetcher, {
    keepPreviousData: true,
    fallback: getAuthFallback(),
  })

  useEffect(() => {
    if (parentContext) mutate(parentContext.data)
  }, [parentContext, mutate])

  const authed = useMemo(() => !!data?.authToken, [data])

  const setAuthDetails = useCallback(
    async (details: AuthDetails): Promise<AuthDetails> => {
      doSetAuthDetails(details)

      await mutate(details)
      return details
    },
    [mutate],
  )

  const update = useCallback(
    async (details: AuthDetails): Promise<AuthDetails> => {
      return setAuthDetails(details)
    },
    [setAuthDetails],
  )

  const doLogin = useCallback(
    async (
      email: string,
      password: string,
      opts: AuthOptions = {},
    ): Promise<AuthDetails> => {
      setLoading(true)

      let credentials
      if (opts?.gToken) {
        credentials = { gtoken: opts.gToken }
      } else {
        credentials = {
          username: email,
          password: password,
        }
      }

      const identifiedDevice = localStorage.getItem('identifiedDevice')
        ? 'true'
        : 'false'

      return fetchV3Api('user_token', {
        method: 'POST',
        body: JSON.stringify({
          auth: {
            ...credentials,
            reactivate: !!opts?.reactivate,
          },
          code: opts?.twoFactorCode ? opts?.twoFactorCode : null,
          session_app: getApp(),
          session_token: await getSessionToken(),
        }),
        headers: {
          'Identified-Device': identifiedDevice,
        },
      })
        .then(res => {
          return match(res)
            .with({ status: 201 }, async res => {
              const body = await res.json()
              await update({ authToken: body.data.token })

              if (!localStorage.getItem('identifiedDevice')) {
                localStorage.setItem('identifiedDevice', 'true')
              }

              return body
            })
            .with({ status: 200 }, _ =>
              Promise.reject(AuthErrors.NeedTwoFactor),
            )
            .with({ status: 404 }, _ => Promise.reject(AuthErrors.Invalid))
            .with({ status: P.any }, _ => Promise.reject(AuthErrors.Unknown))
            .exhaustive()
        })
        .then(data => {
          setSuccess(true)
          setLoading(false)
          return data
        })
        .catch(err => {
          setLoading(false)
          setError(err)
        })
    },
    [update, setLoading, setError, setSuccess],
  )

  const doLogout = useCallback(() => {
    return update({ authToken: null })
  }, [update])

  return {
    authed,
    success,
    data,
    loading: parentContext?.loading || loading,
    error: parentContext?.error || error,
    update,
    doLogin,
    doLogout,
  }
}

function useNewAuth(): AuthState {
  const cookieToken = getDocumentCookie(AUTH_TOKEN_COOKIE)
  const fetcher = useCallback(() => {
    if (!cookieToken) return null

    return fetchAuthApi('/tokens', { method: 'POST' })
      .then(resp => resp.json())
      .then(respBody => respBody.token)
  }, [cookieToken])

  const { data: authToken } = useSWR('local:auth-ctx-token', fetcher, {
    refreshInterval: 1000 * 60 * 45,
    keepPreviousData: true,
    revalidateOnReconnect: true,
    revalidateOnFocus: false,
    fallbackData: cookieToken || undefined,
  })

  let jwtDecoded: JwtPayload | undefined
  try {
    jwtDecoded = authToken ? (jwtDecode(authToken) as JwtPayload) : undefined
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error decoding auth token (useAuth)', e)
  }

  const data: AuthDetails = {
    authed: !!cookieToken,
    authToken: authToken,
    sessionToken: 'no-session-token',
    appName: 'dashboard',
    jwtPayload: jwtDecoded,
  }

  return {
    authed: !!authToken,
    success: undefined,
    data: data,
    loading: undefined,
    error: undefined,
    update: () => Promise.reject('Cannot update new Auth data flow'),
    doLogin: () => Promise.reject('Cannot login via doLogin - use useLogin()'),
    doLogout: () =>
      Promise.reject('Cannot logout via doLogout - use useLogin()'),
  }
}
