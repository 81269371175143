import { v4 as uuidv4 } from 'uuid'
import jwtDecode from 'jwt-decode'
import { AuthDetails, JwtPayload } from '@crystal-eyes/types'
import {
  deleteCookie,
  getCookie,
  getDocumentCookie,
  setCookie,
} from '@crystal-eyes/lib/browser'
import {
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from '@crystal-eyes/lib/constants'

export type { AuthDetails }

export async function getAuthDetails(): Promise<AuthDetails> {
  return _getAuthCookieDetails()
}

async function _getAuthCookieDetails(): Promise<AuthDetails> {
  const authToken = await getCookie(CRYSTAL_AUTH_TOKEN)
  const sessionToken = await getSessionToken()

  const jwtDecoded = authToken
    ? (jwtDecode(authToken) as JwtPayload)
    : undefined

  return {
    authed: !!authToken,
    authToken,
    sessionToken,
    jwtPayload: jwtDecoded,
    appName: getApp(),
  }
}

export function getAuthFallback(): AuthDetails | object {
  if (
    typeof document === 'undefined' ||
    typeof document?.cookie === 'undefined'
  ) {
    return {}
  }

  const authToken = getDocumentCookie(CRYSTAL_AUTH_TOKEN)
  const sessionToken = getDocumentSessionToken()

  const jwtDecoded = authToken
    ? (jwtDecode(authToken) as JwtPayload)
    : undefined

  return {
    authed: !!authToken,
    authToken,
    sessionToken,
    jwtPayload: jwtDecoded,
    appName: getApp(),
  }
}

export async function getSessionToken(): Promise<string> {
  return getCookieSessionToken()
}

export function getDocumentSessionToken(): string | null {
  return getDocumentCookie(CRYSTAL_SESSION_TOKEN)
}

async function getCookieSessionToken(): Promise<string> {
  let sessionToken = await getCookie(CRYSTAL_SESSION_TOKEN)

  if (!sessionToken) {
    sessionToken = uuidv4()
    setCookie(CRYSTAL_SESSION_TOKEN, sessionToken)
  }

  return sessionToken
}

export function getApp(): string {
  return 'dashboard'
}

export async function doSetAuthDetails(details: AuthDetails) {
  doSetCookieDetails(details)
}

async function doSetCookieDetails(details: AuthDetails) {
  if (details.authToken) {
    setCookie(CRYSTAL_AUTH_TOKEN, details.authToken)
  } else {
    deleteCookie(CRYSTAL_AUTH_TOKEN)
    deleteCookie(CRYSTAL_SESSION_TOKEN)
  }

  if (details.sessionToken) {
    setCookie(CRYSTAL_SESSION_TOKEN, details.sessionToken)
  }
}
