import { authApiUrl } from '@crystal-eyes/config'
import { AuthDetails } from '@crystal-eyes/types'
import { trackExecution } from '@crystal-eyes/utils/instrumentation'
import logger from '@crystal-eyes/utils/logger'
import { getOriginApp } from '@crystal-eyes/lib/origin_app'
import getAuth from '@crystal-eyes/data/getAuth'

export const AUTH_TOKEN_REVALIDATE_SECS = 60 * 45

export function getApiUrl(path: string) {
  return `${authApiUrl}/${path}`
}

export function fetchAuthApi(path: string, options: any = {}) {
  const url = getApiUrl(path)

  return trackExecution<Response>(`Auth API: ${path}`, () => {
    return fetch(url, {
      credentials: 'include',
      ...options,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
      },
    }).then(async resp => {
      if (resp.status > 399)
        return Promise.reject({ status: resp.status, body: await resp.json() })
      return resp
    })
  })
}

export async function getSSODetails(opts: {
  email: string
  redirectUri?: string
}) {
  const { email, redirectUri } = opts

  const params: { [key: string]: string } = { email: email }
  if (redirectUri) params.redirect_uri = redirectUri

  return fetchAuthApi('tokens/sso?' + new URLSearchParams(params))
    .then(resp => resp.json())
    .catch(err => {
      logger.error('Fetch SSO details', err)
    })
}

export async function getAuthToken(opts: { refresh?: string } = {}) {
  const body = opts.refresh ? { refresh: opts.refresh } : ''

  const authToken = await fetchAuthApi('tokens', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body),
    next: {
      revalidate: AUTH_TOKEN_REVALIDATE_SECS,
      tags: ['auth', 'auth-token'],
    },
  })
    .then(resp => resp.json())
    .then(data => data.token)

  return authToken
}

export function fetchAuthApiAuthed(
  auth: AuthDetails | undefined | null,
  path: string,
  options: RequestInit & { fetch?: any } = {},
  params?: Record<string, any>,
): Promise<Response> {
  const authHeaders: any = auth?.authToken
    ? {
        Authorization: `Bearer ${auth.authToken}`,
        Session: auth.sessionToken || '',
        App: auth.appName || 'dashboard-unknown-app',
        'Origin-App': getOriginApp(),
      }
    : {}

  if (!authHeaders)
    return Promise.reject('No auth headers sent to fetchV3ApiAuthed')
  const queryString = params ? objectToQueryString(params) : ''
  const url = `${authApiUrl}/${path}${queryString}`

  const authedOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
      ...(process.env.RUNTIME_ENV === 'server'
        ? { 'x-crystal-nextjs': '245e5f31-d71c-4403-bec2-8f05f0c46982' }
        : {}),
      ...authHeaders,
    },
  }

  const chosenFetch = options?.fetch ? options.fetch : fetch

  return trackExecution<Response>(`V3 API: ${path}`, () => {
    return chosenFetch(url, authedOptions).then((resp: Response) => {
      if (resp.status > 399) return Promise.reject(resp)
      else return resp
    })
  })
}

function objectToQueryString(params: Record<string, any>) {
  return `?${Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')}`
}

export const authedFetchAuthApi = async (
  path: string,
  options: RequestInit & { fetch?: any } = {},
  params?: Record<string, any>,
): Promise<any> => {
  const authData = await getAuth()
  return fetchAuthApiAuthed(authData, path, options, params)
}
