import { AUTH_TOKEN_COOKIE } from '@crystal-eyes/lib/constants'

type CookieTokens = {
  type: 'cookies'
  auth: string
  session: string
  page: string
}

export type AuthSource = CookieTokens

const appEnv = process.env.APP_ENV || process.env.NODE_ENV

const APP_COOKIES: { [key: string]: AuthSource } = {
  production: {
    type: 'cookies',
    auth: 'CRYSTAL_AUTH_TOKEN',
    page: 'CRYSTAL_PAGE_TOKEN',
    session: 'CRYSTAL_SESSION_TOKEN',
  },

  staging: {
    type: 'cookies',
    auth: 'CRYSTAL_STAGING_AUTH',
    page: 'CRYSTAL_STAGING_PAGE',
    session: 'CRYSTAL_STAGING_SESS',
  },

  development: {
    type: 'cookies',
    auth: 'CRYSTAL_DEV_AUTH',
    page: 'CRYSTAL_DEV_PAGE',
    session: 'CRYSTAL_DEV_SESS',
  },

  test: {
    type: 'cookies',
    auth: 'CRYSTAL_AUTH_TOKEN',
    page: 'CRYSTAL_SESSION_TOKEN',
    session: 'CRYSTAL_PAGE_TOKEN',
  },
}

export let authSource: AuthSource | undefined =
  APP_COOKIES[appEnv || 'development']

if (process.env.AUTH_COOKIE_AUTH) authSource.auth = process.env.AUTH_COOKIE_AUTH
if (process.env.AUTH_COOKIE_PAGE) authSource.page = process.env.AUTH_COOKIE_PAGE
if (process.env.AUTH_COOKIE_SESSION)
  authSource.page = process.env.AUTH_COOKIE_SESSION

if (process.env.USE_AUTH_API == 'true') authSource.auth = AUTH_TOKEN_COOKIE

export function setAuthSource(source: AuthSource) {
  authSource = source
}

export let enrichmentApiHost: string | undefined =
  appEnv === 'production'
    ? 'https://api.crystalknows.com'
    : 'https://api.staging.crystalknows.com'

if (process.env.ENRICHMENT_API_BASE)
  enrichmentApiHost = process.env.ENRICHMENT_API_BASE

export let graphqlApiUrl: string | undefined = `${enrichmentApiHost}/api`

export let adminApiUrl: string | undefined =
  appEnv === 'production'
    ? 'https://admin-api.crys.io/api'
    : 'https://admin-api.staging.crys.io/api'

if (process.env.ADMIN_API_BASE) adminApiUrl = process.env.ADMIN_API_BASE

export const teamsApiUrl =
  appEnv === 'production'
    ? 'https://teams-api.crystalknows.com/graphql'
    : 'https://teams-api.staging.crystalknows.com/graphql'

export const pmApiUrl =
  appEnv === 'production'
    ? 'https://api.premeeting.com/graphql'
    : 'https://api.staging.premeeting.com/graphql'

export let authApiUrl =
  appEnv === 'production'
    ? 'https://auth.crystalknows.com'
    : 'https://auth.staging.crystalknows.com'

if (process.env.AUTH_API_BASE) authApiUrl = process.env.AUTH_API_BASE

export function setGraphqlApiUrl(url: string) {
  graphqlApiUrl = url
}

export let v3ApiUrl: string | undefined =
  appEnv === 'production'
    ? 'https://api.crystalknows.com'
    : 'https://api.staging.crystalknows.com'

if (process.env.V3_API_BASE) v3ApiUrl = process.env.V3_API_BASE

export function setV3ApiUrl(url: string) {
  v3ApiUrl = url
}
